var advocates = (function () {
    function init() {
        initSliders();
        generateVcard();
        enableTabSwitchLink();
    }

    function initSliders() {
        $(".advocates .owl-carousel").each(function () {
            $(this).owlCarousel({
                dots: false,
                nav: false,
                loop: false,
                dotsEach: true,
                navText: [
                    "<i class='prev-icon'></i>",
                    "<i class='next-icon'></i>"
                ],
                responsive:{
                    0:{
                        items: 1,
                        stagePadding: 15,
                        singleItem: true,
                        dots: false,
                        center: true,
                        margin: -85,
                        loop: true
                    },
                    992: {
                        items: 3,
                        stagePadding: 15,
                        center: true,
                        margin: -85,
                        loop: true
                    },
                    1200:{
                        items: parseInt($(this).attr("data-items"))
                    }
                },
            });
        })
    }

    function generateVcard() {
        if($('.vcard-container').length) {
            var link = vCard.export(ENV.vCardInfo, ENV.vCardInfo.fn, false) // use parameter true to force download
            $('.vcard-container').append(link)
        }
    }

    function enableTabSwitchLink() {
        var link = $(".displayPublication");
        console.log(link);
        if(!link.length) return;
        $(link).on("click", function (event) {
            event.preventDefault();
            var tab = $("#nav-" + $(this).data("category") + "-tab");
            if(!tab.length) return;
            tab.click();
            var offsetTop = $(tab).offset().top - $(tab).height() - $(".fixed-top").height() - 15;
            $("html, body").animate({
               scrollTop: Math.max(0, offsetTop)
            });
        });
    }

    return {
        init: init
    };
})(jQuery);
