var scrollShownTabIntoView = (function () {
    function init () {
        $('.editorial-block--pills a[data-toggle="pill"]').on('shown.bs.tab', function (e) {
            var isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;
            if(!isMobile) return;
            var eventHref = e.target.href;
            var id = eventHref.substr(eventHref.indexOf("#"));
            var tab = document.querySelector(id);
            $("html, body").animate({
                scrollTop: $(tab).offset().top - 2*$(".fixed-top").height()
            });
        })
    }

    return {
        init: init
    };
})(jQuery);
