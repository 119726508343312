var editorialBlockMobileImageAdjuster = (function() {

    function init() {
        setCorrectHeight();
        window.addEventListener("resize", setCorrectHeight);
    }

    function setCorrectHeight() {
        $(".editorial-block .editorial-block-img").each(
            function() {
                var url = $(this).css("backgroundImage").match(/url\("(.*)"\)/)[1];
                if(!url || !window.matchMedia("only screen and (max-width: 768px)").matches) {
                    $(this).css("height", "");
                    $(this).css("min-height", "");
                    return null;
                }
                var img = new Image();
                img.onload = function() {
                    var height = Math.round(img.height * $(window).width() / img.width) + "px";
                    $(this).css("height", height);
                    $(this).css("min-height", height);
                }.bind(this);
                img.src = url;
            }
        )
    }

    return {
        init: init
    };
})(jQuery);