var sliders = (function () {
    var mainSliderCarousel = [];
    var slider = $(".sliders .owl-carousel");
    var countSliderItem = $(".item", slider).length;
    function init() {
        initSliders();
        $(document).ready(function () {
            adaptVideoSlider();
        })
    }

    function adaptVideoSlider() {
        if ($(".html5", slider).length) {
            $(".html5", slider).each(function () {
                $(this).css({
                    'height': slider.outerHeight()
                })
            });
        }
    }

    function initSliders() {
        var elems;
        if (slider.length) {
            slider.each(function () {
                elems = $(this).owlCarousel({
                    items: 1,
                    margin: 0,
                    singleItem: true,
                    nav: true,
                    navText: [
                        "<i class='prev-icon'></i>",
                        "<i class='next-icon'></i>"
                    ],
                    autoplayTimeout: 4000,
                    autoplayHoverPause: true,
                    autoplay: true,
                    smartSpeed:450,
                    mouseDrag:false,
                    loop: $(this).children(".item").length > 1 ? true : false,
                    dots: false,
                    onChanged: onChange
                });
                mainSliderCarousel.push(elems);
            })
        }
    }

    function onChange(sliders) {
        sliderAnimation(sliders);
    }

    function sliderAnimation(slider) {
        var currentSlider = $(slider.target).find(".owl-item").eq(slider.item.index);
        var slidersWyswyg = currentSlider.find(".sliders-wyswyg");

        if (slidersWyswyg.length) {
            var title = $(".sliders-title-box:visible", currentSlider).not('.invisible');

            slidersWyswyg.addClass('fadeIn animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                slidersWyswyg.removeClass('fadeIn animated');
            });

            title.addClass('slideInDown animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                title.removeClass('slideInDown animated');
            });
        }
    }

    return {
        init: init
    };
})(jQuery);
