var latestNews = (function () {
    function init() {
        initSliders();
    }

    function initSliders() {
        $(".latest-news .owl-carousel").owlCarousel({
            margin: 15,
            dots: true,
            nav: false,
            loop: false,
            responsive:{
                0:{
                    items: 1,
                    stagePadding: 15,
                    singleItem: true,
                    dots: false,
                    center: true,
                    margin: -55
                },
                767:{
                    items: 2
                },
                992: {
                    items: 3
                },
                1200:{
                    items: 4
                }
            },
        });
    }

    return {
        init: init
    };
})(jQuery);
