var publicationsManager = (function () {
    function init () {
        $(".publication-nav .nav-pills .nav-item .nav-link").click(
            function(e) {
                console.log(e);
                var clickTarget = e.originalEvent.target;
                var link = $(clickTarget).find("a");
                var scollTarget = $(link).data("target");
                var isMobile = !window.matchMedia("only screen and (min-width: 992px)").matches;
                if(isMobile) {
                    var navRoot = $(clickTarget).closest(".publication-nav");
                    var isOpen = $(navRoot).hasClass("open");
                    if(!isOpen) {
                        $(navRoot).addClass("open");
                    }
                }
            }
        );
    }

    return {
        init: init
    };
})(jQuery);
