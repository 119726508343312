var navbarManager = (function () {
    function init() {
        var toggleNavbarBtn = $(".navbar-toggler");
        var navbarCloseBtn = $(".nav-close");
        $(document).ready(function () {
            $(".side-menu li").on('mouseover',function () {
                $(this).addClass('show');
            });
            $(".side-menu li").on('mouseout',function () {
                $(this).removeClass('show');
            });
            toggleNavbarBtn.on("click",function (e) {
                navToggler();
                e.preventDefault();
            });
            navbarCloseBtn.on("click", function (e) {
                navToggler();
                e.preventDefault();
            })
        });
        toggleNavbar();

    }

    function toggleNavbarTransparentAndDefault() {
        var menuTop = $('.navbar');

        if ($(this).scrollTop() > 100) {
            menuTop.addClass('navbar-sticky');
            menuTop.addClass('shadow');
        } else {
            menuTop.removeClass('navbar-sticky');
            menuTop.removeClass('shadow');
        }
    }

    function toggleNavbar() {
        $(".navbar-collapse").on("show.bs.collapse", function () {
            $(".navbar-toggler").addClass('open')
        });
        $(".navbar-collapse").on("hide.bs.collapse", function () {
            $(".navbar-toggler").removeClass('open')
        });
    }

    function navToggler() {
        var navbarToToggle = $(".nav-collapse");
        var body = $("body");

        if (navbarToToggle.hasClass('show')) {
            navbarToToggle.removeClass('show');
            body.removeClass('no-scroll');
        } else {
            navbarToToggle.addClass('show');
            body.addClass('no-scroll');
        }
    }

    return {
        init: init
    };
})(jQuery);
