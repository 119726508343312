$(document).ready(function () {
    cookieInformationLayer.init();
});

var cookieInformationLayer = {
    cookieName: 'cookie_performance',
    tpl: LABELS.cookieLayer,
    init: function () {
        var that = this;
        if (!this.hasAccepted()) {
            this.displayInfoLayer();
        }
        $(document).on("submit",".gdpr form.advanceCookieForm", function () {
            var form = $(this);
            var cookieNotRequired = $(".cookieNotRequired", form);
            if (cookieNotRequired.is(':checked')) {
                //LOAD COOKIE_PERFORMANCE == Y
                that.accept();
            } else {
                //LOAD COOKIE_PERFORMANCE == N
                that.notAccept();
            }
            that.closeInfoLayer();
        });
        $(document).on("click",".gdpr .cookieDetails", function (e) {
            var id = $(this).attr("href");
            var target = $(id);
            if (target.length) {
                target.on('show.bs.collapse', function () {
                    $('.gdpr').animate({
                        scrollTop: target.position().bottom
                    }, 1000);
                });
            }
            e.preventDefault();
        });
        $(document).on("click",".gdpr .tab-left ul li a",function (e) {
            var toShow = $(this).attr("href");
            $(".gdpr .custom_tab-content").removeClass("view");
            $(toShow).addClass("view");
            $(".gdpr .tab-left ul li a").removeClass("view");
            $(this).addClass("view");
            e.preventDefault();
            return false;
        });
    },
    hasAccepted: function () {
        //return false;
        return $.cookie(this.cookieName);
    },
    displayInfoLayer: function () {
        //TODO: change with some smart function.
        $("body").prepend(this.tpl);
    },
    closeInfoLayer: function () {
        $(".gdpr").slideUp("fast", function () {
            $(this).remove();
        });
    },
    accept: function () {
        this.closeInfoLayer();
        $.cookie(this.cookieName, 'Y', {expires: 30, path: '/'});
    },
    notAccept: function () {
        this.closeInfoLayer();
        $.cookie(this.cookieName, 'N', {expires: 30, path: '/'});
    }
};