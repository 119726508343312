$(function () {
    navbarManager.init();
    sliders.init();
    advocates.init();
    latestNews.init();
    scrollShownTabIntoView.init();
    publicationsManager.init();
    newsImageCarouselManager.init();
    editorialBlockMobileImageAdjuster.init();
});
